import React from 'react'
import axios from 'axios'
import { MdThumbUp } from "react-icons/md"

export default class Like extends React.Component {
  constructor() {
    super()

    this.state = {
      like: 0,
      disabled: false,
    }

    this.likeIt = this.likeIt.bind(this)
  }

  likeIt() {
    this.setState({disabled: true})

    axios.post(`${process.env.API_URL}/articles/${this.props.id}/like`)
      .then(res => {
        this.setState({like: this.state.like + 1})
      })
      .catch(err => {
        console.error(err);
      })
  }

  componentDidMount() {
    axios.get(`${process.env.API_URL}/articles/${this.props.id}`)
      .then(res => {
        this.setState({like: res.data.like})
      })
      .catch(err => {
        this.setState({error: err})
      });
  }

  render() {
    const colorClass = this.state.disabled ? 'has-text-grey' : 'has-text-success'

    return (
      <div>
        <span>
          有用 {this.state.like || ''}
          <MdThumbUp
            onClick={() => {
                if (this.state.disabled) return;

                this.likeIt()
              }}
            className={`${colorClass}`}
            css={{
              marginLeft: '0.2rem',
              cursor: this.state.disabled ? `default` : `pointer`,
            }}
          />
        </span>
      </div>
    )
  }
}

